.friendcontainer {
  padding: 15px;
}
.friend {
  display: flex;
  min-width: 300px;
  position: relative;
  width: 100%;
}

.friend img {
  width: 80px;
  height: 80px;
  border-radius: 79px;
  border: 2px solid #aaa;
  object-fit: cover;
  margin-left: -3px;
  margin-right: 9px;
}

.infodivfriend{
  margin-top: -6px;
  overflow: hidden;
}
.friend p {
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  margin: 22px 0 5px;
  font-family: sans-serif;
}
.infofriendspan {
  font-size: 15px !important;
  font-weight: 700 !important;
  color: #009aad!important;
  position: inherit !important;
}

.friend span { 
  color: red;
  margin-left: auto;
  margin-right: 7px;
  font-size: 28px;
  font-weight: 600;
  align-items: center;
  display: flex;
  margin-top: -2px;
}

.titlefriend h1 {
  color: #009aad;
  font-size: 22px;
  margin-left: 9px;
  position: relative; 
}

.allFriends {
  /*display: grid;
  grid-template-columns: 0fr 1.5fr; */
  column-gap: 33px;
  row-gap: 1rem;
  justify-content: space-around;
}

.allFriendsseg {
  /* margin-top: 20px; */
  /* height: 37%; */
  /* margin-left: 13%; */
  display: grid;
  grid-template-columns: 0fr 1.5fr;
  /* max-width: 78%; */
  column-gap: 13px;
  row-gap: 1rem;
}

.titlefriend {
  display: flex; 
  margin-bottom: 10px;
}
.titlefriend img {
  width: 44px;
  height: 32px;
  position: relative; 
}

.friend .imgCancel{
    border: none !important;
    width: 24px!important;
    height: 24px!important;
    cursor: pointer;
    margin: 0;
    right: 10px;
    position: absolute;
    top: 20px;
    display: block;
}
.friend .friend_heart {
  position: absolute;
  width: 24px;
  height: 24px; 
  border: none;
  margin: 0;
  right: 10px;
  position: absolute;
  top: 20px;
  display: block;
  cursor: pointer;
}
.friend-request {
  background: lightgreen;
  padding: 10px;
}
.friend-sent {
  background: lightgoldenrodyellow;
  padding: 10px;
}
.profileHeader { 
  background: #009aad;
  padding: 15px 30px;
} 
.profilePicture {
  width: 80px;
  margin-right: 15px;
  height: auto;
  border: solid 3px white;
  border-radius: 100%;
}
.profileHeader-element h2 {
  color: white;
}
.profileHeader-element {
  color: white;
}
.profilePicto {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 10px;
}
.profileHeader-element p {
  margin-right: 10px;
}
.profileHeader-bio {
  height: calc(100% - 20px);
}
@media(max-width: 767px){
  .profileHeader {
    padding: 50px 10px 10px 10px;
  }
  .profileHeader-element {
    margin-right: 5px;
    margin-bottom: 15px;
    display: inline-block;
    vertical-align: top;
  }
  .profilePicture {
    width: 50px;
    margin-right: 5px;
  }
}