  .articles {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
  }
  .articlesfav {
    display: flex;
    flex-direction: column;
    margin: 50px 20px;
  }

  .features {
    margin-top: 20px;
    /* height: 37%; */
    /* margin-left: 13%; */
    display: grid;
    grid-template-columns: 8fr 4.5fr 4.5fr;
    /* max-width: 78%; */
    column-gap: 1rem;
    row-gap: 1rem;
  }
  .features1 {
    margin-top: 20px;
    /* height: 37%; */
    /* margin-left: 13%; */
    display: grid;
    grid-template-columns:  4.5fr 4.5fr 8fr;
    /* max-width: 78%; */
    column-gap: 1rem;
    row-gap: 1rem;
  }

  .featuresfav { 
    margin-bottom: 20px;
  }

  .item {

    /* width: 69vh; */
    /* margin-left: 92px; */
    /* display: flex; */
    flex-direction: column;

    background-color: #f9f9fb;
    box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.507);
    overflow: hidden;
    border-radius: 30px;
    transition: all 0.3s;
    height: 311px;
    position: relative;
  }

  .itemfav {
    display: flex;
    cursor: pointer;
    background-image: linear-gradient(to right bottom, #ffffff, #ffffff);
    overflow: hidden;
    transition: all 0.3s;  
    border: solid 1px #009aad;
  }

  .item:hover {
    transform: translateY(-1.1rem);
  }
  /* .itemfav:hover {
    transform: translateY(-1rem);
  } */

  .item-photo {
    width: 100%;
    align-self: center;
    height: 180px;
   

  }
  .item-photofav {
    width: 150px; 
    border-radius: 30px;
    height: 100%;
    object-fit: cover;
}
  .favphoto{
    
    align-self: center;
    height: 217px;
    border-radius: 30px;

  }

  .item-headerfav {
    margin-top: 21px;
  }

  .item-icon {
    width: 2.4rem;
    height: 2.4rem;
  }

  .item-title {
    text-align: left;
    font-size: 19px;
    font-weight: 900;
    margin-top: 1px;
    /* margin-left: -150px; */
    font-family: sans-serif;
    margin-left: 15px;
  }

  .item-titlefav {
    text-align: left;
    font-size: 21px;
    font-weight: 900;
    margin-top: 1px;
 
    font-family: sans-serif;
  
  }

  .item-text {
    font-weight: 600;
    font-size: 12px;
    text-align: left;
    margin-top: -17px;
    margin-left: 16px;
  }
  .item-textfav {
    text-align: left;
    font-family: sans-serif;
    font-size: 14px;
    margin-bottom: 15px;
  }

  .item-link:link,
  .item-link:visited {
    display: inline-block;
    font-size: 1.6rem;

    padding: 1.6rem 2.4rem;
    text-decoration: underline #099268;
    color: #099268;

    /* stick link to the bottom of item */
    margin-top: auto;
  }

  .item-link:hover,
  .item-link:active {
    color: #3aa886;
    text-decoration: underline #3aa886;
  }

  /* TABLETS */
  @media (max-width: 950px) {
 

    .features {
      grid-template-columns: 1fr 1fr;
      max-width: 60rem;
    }
    .features1 {
      grid-template-columns: 1fr 1fr;
      max-width: 60rem;
    }

    .item1 {
      grid-column: span 2;
    }

    .item-title {
      font-size: 3rem;
    }

    .item-text {
      font-size: 2rem;
    }

    .item-link:link,
    .item-link:visited {
      font-size: 2rem;
    }
  }

  /* MOBILES */
  @media (max-width: 650px) {
    .features {
      grid-template-columns: 1fr;
      max-width: 30rem;
    }
    .features1 {
      grid-template-columns: 1fr;
      max-width: 30rem;
    }

    .item1 {
      grid-column: span 1;
    }


  }

  .imgdiv{
    margin-left: 20px;
   
    height: 25px;
    border-radius: 20px;
    margin-bottom: 7px;
  }
  .imgdivfav{

   
    height: 28px;
    border-radius: 20px;
    margin-bottom: 7px;
  }
  .imgdiv  img{
  
    border-radius: 10px;
  
  }
  .imgdivfav  img{
  
    width: 35px;
  
  }
  .imgdiv p{
    margin-left: 9px;
    position: relative;
    top: -7px;
    font-size: 13px;
    font-weight: 600;
  }
  .imgdivfav p{
    margin-left: 8px;
    margin-top: 7px;
    font-size: 13px;
    font-weight: 700;
  }
.titleDiv  span{
  position: relative;
  top: 15px;
  margin-right: 5px;
  font-size: 38px;
  color: #0098C7;
}
.titleDiv  h1{
  color: #0098C7;
  font-size: 25px;}

  
  .arttfav{
    width: 80%;
    /* margin-left: 21px; */
  }

  .favicon{
      
      place-items: center;
    
  
      display: grid;
  }
  .favicon img{
    height: 36px;
    width: 58px;
  }

  
  .titleDivfav{
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 21px;
  }
  .titleDivfav button{
    margin-top: -49px;
    border: none;
    padding: 10px;
    color: white;
    background-color: #009AAD; 
    font-size: 13px; 
    border-radius: 40px;
    font-weight: 700;
    font-family: sans-serif;
    text-transform: inherit;}


    .popup {
      position: fixed;
      top: 10%;
      background-color: #00a9c1;
      border-radius: 5px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      width: 100%; 
      max-width: 600px; 
      z-index: 9;
    }
    .popup h2 {
      color: white;
      font-size: 20px;
      padding: 5px;
  }
    @media(min-width: 768px){
      .popup {
        left: 50%;
        max-width: 600px;
        margin-left: -300px;
      }
    }
    .headtitle {
      padding-left: 20px;
      width: 100%;
      background-color: #008EB5;
      display: flex;
      padding-right: 20px;
      border-radius: 8px 8px 0 0;
  }
    .headtitle h1{
      color: white;
      padding: 15px 0;
      font-weight: bold;
    }
    .headtitle span{
      cursor: pointer;
      position: relative;
      margin-right: 0;
      margin-left: auto;
      top: 20px;
      font-size: 24px; 
    } 
    .Message{
      margin: 24px;
    }
    .MsgInput::placeholder {
      color: #ffff; /* Your desired placeholder color */
    }
    .MsgInput {
      width: 100%; 
      font-size: 16px;
      border-radius: 10px;
      background-color: #008EB5;
      border: none;
      outline: none; 
      color: white;
      padding: 10px; 
    }

    .actiondiv {
      display: flex;
      text-align: center;
      flex-direction: column;
      padding: 0 15px;
  }
    
    .actiondiv input {
      width: 24px;
      height: 24px;
      margin: 40px auto 10px auto;
  }
    
    .actiondiv span{
      font-size: 30px;
    }
    

    .socialicons{
      display: grid;
      height: 0;
      position: relative;
      left: -56px;
    }
     
     

    .divli img{
      width: 33px;
      height: 30px;
    }
    
    
    .divstart img{
      position: relative;
      left: 8px;
      width: 22px;
      height: 19px;
      position: relative;
      /* margin-right: 0px; */
    }
    
    
    .blur-background {
      backdrop-filter: blur(5px); /* Adjust the blur intensity as needed */
      pointer-events: none; /* Prevents the blurred overlay from capturing events */
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      /* z-index: 9999; Ensure the blur overlay is above other elements */
    }


    .startArticle{
      position: relative; 
      width: 22px;
      height: 22px; 
      margin-right: 5px;
    }
    .divstart{
      /* border-bottom: 1px solid gray; */
      width: 19px;
      margin-right: 18px;
    }

    .zicon{
      width: 38px;
      height: 30px;
      margin-left: 4px;
    }

    .divliinput{
      margin-top: 15px;
      margin-left: -17px;
      padding: 0px 12px;
    }

  .divliinput input{
    height: 27px;
    border: none;
    border-radius: 20px;
  }

  .divliinput input::placeholder{
 margin-right: 4px;
  }

  .item-photowrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding:15px;
  }

  img.item-photofav-add {
    width: 80px;
    border-radius: 20px;
    display: inline-block;
    margin: 0 10px 10px 0;
  }
.search-input {
    padding: 8px 15px;
    border: solid 1px #999;
    border-radius: 8px;
    margin: 0px auto 15px auto; 
    width: 250px;
    display: block;
}

  @media(max-width: 700px){
    .item-headerfav {
      margin-top: 5px!important;
    }
    .item-photowrapper {
      padding: 5px!important;
    }
    .item-titlefav { 
      font-size: 12px; 
    }
    .articlesfav {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      margin-right: 10px;
    }
    .item-textfav{
      display: none;
    }
    .item-photofav {
      width: 100px;
      border-radius: 10px;
  }
  .arttfav p {
    font-size: 10px;
  }
  .search-input {
    padding: 8px 15px;
    border: solid 1px #999;
    border-radius: 8px;
    margin: 15px 0;
    width: 100%;
}
  .popup { 
    top: 0; 
  }
}
