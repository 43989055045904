.btnpreprofile{ 
  padding: 8px 15px;
  background: #ffe02b; 
  border-radius: 20px;
  border-color: #ffe50000;
  font-size: 14px;
  font-family: system-ui;
  font-weight: 500;
}
  .avat{
    height: 148px !important;
    width: 149px !important;
  }
  .allcontainer {
    width: 100%;  
    flex-direction: column;
  } 

  @media(min-width:992px) {
    .allcontainer {
      width: calc(100vw - 250px);
    }
  }
  .homecontainer {
        background: #f0f0f0;
    /* height: 100vh; */
    /* display: flex; */
    /* flex-direction: column;
    align-items: center;
    justify-content: center; */
    /* height: 51vw; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: auto;
    /* width: 100vw;
    height: 100vh; */
  }
  
  #___gcse_0 input#gsc-i-id1 { margin: 0!important; }

  .profilename {
    text-align: center;
    /*position: relative;
    left: 1vw !important;*/
  }
  .profilename h2{
      font-size: 29px;
      margin-bottom: 6px;
      min-width: 20vh;
  }
  /* .profile{
    margin-top: -71vh;
  } */

  .css-1vm0r4s-MuiPaper-root{
    border-radius: 67px !important;
    /* margin: 55px!important; */
  }

  .css-1vm0r4s-MuiPaper-root{
    width: 38vw !important;
    height: 55px !important;
    border-color: #0098C7 !important;
    border: 1px solid #0098C7 !important;
  }

  .droplist{
    justify-content: space-evenly;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 114rem;
    column-gap: 15vw;
    row-gap: 9rem;
    margin-top: 10px;
  }

  /* .list1{
    width: 54%;
  } */

/* 
  .list3{
    width: 54%;
    margin-left: 43%;
  } */

  .profile {
    display: flex;
    justify-content: center;
    /* padding: 50px 15px 25px 15px; */
}

.search_menu_selection {
  font-size: 15px!important;
  border-radius: 40px!important;
  font-weight: 600!important;
  font-family: sans-serif!important;
  text-transform: inherit!important;
}


.search_menu_selection_country {
  font-size: 1px!important;
  border-radius: 40px!important;
  font-weight: 600!important;
  font-family: sans-serif!important;
  text-transform: inherit!important;
}
@media (max-width: 1280px) {
  .search_menu_selection_country {
    font-size: 15px!important;
  }
}

  .iconProfile {
    width: 80px;
    height: 80px;
    border-radius: 62px;
    border: 2px solid #AAA;
    object-fit: cover;
    margin-left: 0px;
    margin-right: 4px;
    margin-top: 5px;
}

  .btnpreprofile{
    cursor: pointer;
  }

  /* .css-1x0waax-MuiModal-root-MuiPopover-root-MuiMenu-root .MuiPaper-root{
    width: 17% !important;
    top: 286px!important;
    left: 904px!important;
  } */


  .css-1x0waax-MuiModal-root-MuiPopover-root-MuiMenu-root .MuiPaper-root .MuiMenuItem-root{
    width: 300px;
  }

  /* .css-1x0waax-MuiModal-root-MuiPopover-root-MuiMenu-root .MuiPaper-root{
    top: 282px!important;
    left: 1036px!important;
   
  } */
  .MuiMenu-list {
    background: aliceblue;
  }
  .actions{
    /* height: 46px; */ 
    background-color: aliceblue; 
    padding: 5px;
  }
  .actuins{
    background-color: aliceblue;
    padding-block: 1px;
  }
  .actuins span{
    font-size: 18px;
    font-weight: 400; 
    cursor: pointer;
    color: #0098C7;
    display: block;
    padding: 5px 10px;
  }

  .actions span{ 
      font-size: 15px;
      font-weight: 400; 
      cursor: pointer;
      color: #0098C7;
      display: block;
      padding: 5px 10px;
    }

  .divli span{

    position: relative;
    font-size: 43px;
    top: 4px;

    cursor: pointer;
    color: #0098C7;
  }

  .css-6hp17o-MuiList-root-MuiMenu-list{
    background-color:aliceblue ;
  }

  .gsc-search-button-v2:hover {
    border-color: none!important;
    background-color: none!important;
    background-image: none!important;
}

  .ulflag {
    list-style: none!important;
    padding: 0;
    margin: 5px 10px;
  }

  .ulflag li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    list-style: none!important;
    list-style-type: none; 
    content: none !important;
  }



  .flag-icon {
    margin-right: 10px;
  }

  .startArticle{
    position: relative; 
    width: 22px;
    height: 22px; 
    margin-right: 5px;
  }

  
  .actions  a {
    text-decoration: none;
    color: inherit;
    font-size: 22px;
    margin-left: 9px;
  }
  .divli  a {
    text-decoration: none;
    color: inherit;
    font-size: 16px;
    margin-left: 9px;
  }
  .actuins  a {

    font-weight: 600;
    text-decoration: none;
    color: #0098C7;
    font-size: 17px;
    margin-left: 1px;
  }

  /* .sidprof{
    height: 34%;
    
  } */



  .file-input{
    align-items: center;
    display: flex;
    margin-left: -59px;
    position: relative;
    left: 102px;
  }


  .file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  
  .file-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    padding: 10px 12px;
    background-color: #0098C7;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  }
  
  .file-input__label svg {
    height: 16px;
    margin-right: 4px;
  }
  

  .css-1bq3xq-MuiModal-root-MuiPopover-root-MuiMenu-root .MuiPaper-root{
    min-width: 292px !important;
  }

  .icon{
    margin-left: 49px;
  }

  .search{
    margin-left: 80px;
  }
  button.gsc-search-button.gsc-search-button-v2:hover {
    background: none!important;
}
  .bgcontainer{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
  }

  #stack_thing_in_header {
    justify-content: center;
  }

  @media(min-width: 1024px){
    #stack_thing_in_header {
      width: 27vw;
      justify-content: space-evenly;
    }
    .droplist {
      display: none;
    }
  }
  @media(max-width: 1023px){
    .profile { 
      flex-direction: column;
    }
    .profile .icon {
      margin: 0;
    }
    .droplist {
      flex-direction: column;
      row-gap: normal;
    }
  .list1 button, .list2 button, .list3 button {
      width: 100%!important;
      margin: 5px auto;
      display: block;
      position: relative;
  }
  .list1 button > span, .list2 button > span, .list3 button > span {
    display: inline-block!important;
  }
  .css-9tj150-MuiButton-endIcon {
    display: inherit;
    margin-right: 0;
    margin-left: 0;
    position: absolute;
    right: 15px;
    top: 5px;
  }
  #___gcse_0 { 
    margin-top: 15px!important; 
  }
  .search {
    margin: auto;
    width: 280px;
  }
  div#gsc-iw-id1, form.gsc-search-box.gsc-search-box-tools {
    min-width: 100%!important; 
  }
  .profilename h2 {
    font-size: 16px;
    margin-bottom: 6px;
  }
  button.btnpreprofile {
    padding: 5px 8px;
    font-size: 13px;
  }
  img.iconProfile {
    width: 50px;
    height: 50px;
    margin-left: 14px;
    margin-right: 0;
  }
}
/* Public banner */
.public-grey {
  background: #f0f0f0;
}
.public-blue {
  background: #009aad;
  display: flex;
  justify-content: end;
}
.public-blue a.bg-transparent {
  display: inline-block;
  padding: 12px 15px;
  width: 180px;
  border-radius: 100px;
  text-align: center;
}
.public-blue a.bg-white {
  display: inline-block;
  padding: 12px 15px;
  width: 180px;
  border-radius: 100px;
  color: #009aad;
  text-align: center;
}
.public-container {
  min-height: 100vh;
  padding: 15px;
}
@media(max-width: 767px){
  .public-blue a.bg-white {
    display: none;
  }
  div#___gcse_1 {
    background: #f0f0f0;
  }
}
@media(min-width:768px){
  .translated-ltr, .translated-rtl {
    overflow: hidden;
  }
}