.review-block {
    border: solid 1px #009aad;
    position: relative;
}
.review-header {
    background: #009aad;
    color: white;
}
.review-header:hover {
    background: #444;
}
.review-header strong {
    font-size: 18px;
    text-transform: uppercase;
}
.review-header p {
    margin: 0;
    font-size: 16px
}
.article-origin {
    color: #009aad;
}
