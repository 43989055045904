.css-i7rae6-MuiList-root{
      max-height: 100px;
  overflow: auto!important;
    min-height: 100vh !important; 

    background-color: #f0f0f0!important;
    max-width:339px!important;
}

.css-uob957-MuiListSubheader-root{
    background-color: #f0f0f0!important;

    
    max-width: 371px!important;
}

.css-uob957-MuiListSubheader-root{
    background-color: #f0f0f0!important;
}

.spanacc{
    font-size: 15px;
    font-weight: 500;
}
.logo { cursor: pointer}
.title{
    font-size: 19px;
    font-weight: 700;
    /* margin-left: 34px; */
    margin-bottom: 3px;
    color: #008eb5;
}
.css-cveggr-MuiListItemIcon-root{
    min-width: 33px!important;
}

.ff{
    font-size: 12px;
    font-weight: 400;
    padding: 2px;
    color: #008eb5;
}



.spblack{
    color: black;
}

.ff .material-symbols-outlined{
    position: relative;
    top: 5px;
}
.logo{
    width: 162px;
    margin-top: 17px;
    margin-left: 35px;
}
  .friendsent {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  
  .friendsent img { 
    width: 36px;
    height: 36px;
    border-radius: 30px;
    border: 2px solid #AAA;
    object-fit: cover;
    margin-left: 5px;
    margin-right: 10px;
  }
  
  .friendsent .name {
    font-size: 15px;
  }

  .name p{
    margin-top: 0;
    margin-bottom: 3px;
    margin-left: 6px;
  }
  
  .name button{
    height: 28px;
    border: none;
  /* height: 45px; */
  font-size: 15px;
  /* width: 119%; */
  border-radius: 10px;
  font-weight: 700;
  font-family: sans-serif;
  text-transform: inherit;
  width: 113px
  }
  
  .name .btnconferm{
    color: white;
    background-color: #008eb5;
    max-width: 6.9vw;
    margin-right: 9px;
  }
  
  .name .btnsupp{
    color: black;
    background-color: white;    
    max-width: 6.9vw;
  }
  

  .voir{
    color: #008eb5;
    font-size: 18px;
    margin-left: 9px;
    font-weight: 700;
    margin-left: 24px;
    margin-top: 32px;
  }

  .imgicon{
    width: 23px;
    height: 23px;
    position: relative;
    margin-right: 5px;
    top: -3px;
  }
 
  #friend-list {
    margin: 0;
    padding: 0;
  }

  .css-uob957-MuiListSubheader-root{
    position: initial !important;
  }
  
  .css-107g3ff-MuiPaper-root{
    margin-left: 10px!important;
  }

  .iconProfil{
    width: 36px;
    height: 38px;
    border-radius: 77px;
    /* border: 2px solid #AAA; */
    object-fit: cover;
    margin-left: -4px;
    margin-right: 5px;
  }


  .imgiconMessage{
  
    margin-right: 5px;
 
    position: relative;
    height: 18px;
    width: 24px;
    top: -1px;
  }

  .imgiconfav{
    margin-right: 4px;
    position: relative;
    width: 20px;
    /* margin: 0; */
    height: 26px;
    left: -1px;
  }

  div#nested-list-subheader {
    background-color: #f6f4f5 !important ;
    margin-left: -38px;
  }

#sideBar {
    padding-left: 25px;
    min-height: 100vh;
    min-width: 22vw;
}

#search_bar {
    width: 90%;
    max-width: 273px
}
.menuburger { 
    display: none;
}
.menuburger svg {
   width: 40px;
   height: 40px;
}
@media(max-width:767px) {
  .menuburger { 
    display: block;
  }
  .mainsidebar{ 
      position: absolute;
      z-index: 9;
      overflow: auto; 
  }
}