

form.gsc-search-box.gsc-search-box-tools{
    width: 35vw !important;
}

td.gsc-input{
    margin: 0px;
    padding: 0px;
}

div#gsc-iw-id1 {
    width: 35vw;
    height: 55px;
    border: 2px solid #008eb5;
    border-radius: 28px;

}

table#gs_id50 {
    margin-top: 7px;
    margin-left: 10px;
}

tr:has(td#gs_tti50) {
    width: 100px;
}

td.gsib_b {
    border: none;
    display: none;
}

input#gsc-i-id1{ /* search-bar */
    margin-top: 4.5px !important;
    background: none !important;
    background-image: none;
}


button.gsc-search-button-v2 {
    z-index: 1;
    margin-left: -5rem;
    background: rgba(149, 34, 34, 0);
    border: none;
}

button.gsc-search-button-v2 svg{
    fill: black;
    transform: scale(1.6);
}
 
.wrapper_for_bookmark {
    min-height: 220px;
    position: relative;
}
.bookmark {
    position: absolute;
    right: 20px;
    top: 0px;
    width: 30px;
    cursor: pointer;
}
.allcontainer .gsc-tabsArea { 
    border: none;
}
.gsc-table-result {
    display: flex;
}
.allcontainer .gsc-webResult.gsc-result {
    min-height: 160px;
    background: white;
    border-radius: 12px;
    margin-bottom: 15px;
    padding: 12px 10px;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
    border: solid 1px #008eb5;
}
.gs-bidi-start-align.gs-visibleUrl.gs-visibleUrl-breadcrumb {
    color: #666 !important;
}
.allcontainer .gsc-control-cse {
    background: none;
    border: none;
}
.gs-webResult.gs-result {
    position: relative;
} 
#___gcse_1 .gsc-results .gsc-cursor-box {
    background: white;
    padding: 15px;
    border-radius: 10px;
    margin: 0;
}
#___gcse_1 .gsc-results .gsc-cursor-box .gsc-cursor-page {
    text-decoration: none;
    color: #666;
    font-size: 16px;
    background: white;
    border-radius: 100%;
    width: 36px;
    display: inline-block;
    text-align: center;
    height: 36px;
    line-height: 34px;
    border: solid 1px;
}
#___gcse_1  .gsc-results .gsc-cursor-box .gsc-cursor-page:hover, #___gcse_1 .gsc-results .gsc-cursor-page.gsc-cursor-current-page {
    background: #009aad;
    color: white;
    text-decoration: none;
} 
.gsc-table-cell-thumbnail.gsc-thumbnail {
    width: 200px!important;
}
.gs-image-box {
    width: 220px!important;
}
.gs-web-image-box .gs-image, .gs-promotion-image-box .gs-promotion-image {
    max-width: 250px !important;
    height: auto !important;
    display: block;
    object-fit: cover;
    width: 180px;
    border: none !important;
    border-radius: 8px;
    max-height: 500px !important;
}
.gsc-thumbnail-inside {
    max-width: calc(100% - 50px);
}
.gsc-table-cell-snippet-close, .gs-promotion-text-cell {
    max-width: calc(100% - 250px) !important;
}
a.gs-title {
    color: black !important;
    font-weight: bold;
}
.gsc-webResult .gsc-url-top {
    display: none !important;
}
.gsc-control-cse .gs-spelling, .gsc-control-cse .gs-result .gs-title, .gsc-control-cse .gs-result .gs-title * {
    font-size: 20px!important;
}
.gs-snippet {
    font-size: 16px;
}
@media(max-width:479px) {
    .gs-web-image-box .gs-image, .gs-promotion-image-box .gs-promotion-image {
        max-width: 100% !important; 
        width: 100%!important; 
        max-height: 500px !important;
    }
    .gs-image-box, .gsc-table-cell-thumbnail.gsc-thumbnail, .gs-image-box {
        width: 100% !important;
    }
    .gsc-table-result {
        display: block!important;
    }
    .gsc-table-cell-snippet-close, .gs-promotion-text-cell {
        max-width: 100%!important;
        float: left;
    }
}
#pays_large {
    display: none;
}
@media(min-width: 1024px) {
    #pays_large {
        background-color: #f6f4f5;
        display: block;
        z-index: 1;
        overflow: scroll;
        height: 87vh;
    }
    #searchCountry {
        background-color: #f6f4f5;
    }
}
#themes {
    overflow: scroll;
    height: 70vh;
}
#theme_country {
    width: 100%;
    text-align: left;
    margin: 0.25em;
}
.country_selection {
    font-weight: 500;
}

.gsc-results-wrapper-overlay {
    background-color: transparent !important;
}
.gsc-modal-background-image {
    display: none !important;
}
.gsc-results-close-btn {
    display: none !important;
}