
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

* {
	box-sizing: border-box;
}

.Bodylogin {
	background: #f6f5f7;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	height: 100vh;
	/* margin: -20px 0 50px; */
}

.titr {
    color: #009AAD;
	font-size: 30px;
	width: 379px;
	font-weight: bold;
	margin: 0;
}
.containerLogin h1 {
  
	font-weight: bold;
	margin: 0;
}

.containerLogin h2 {
	text-align: center;
}

.containerLogin p {
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
}

.containerLogin span {
	font-size: 12px;
}

.containerLogin a {
	color: #333;
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
}
.containerLogin a:hover {
	color: #0079ff;

    background-color: transparent;
}

.containerLogin button {
	border-radius: 20px;
	border: 1px solid #CAE1E1;
	background-color: #CAE1E1;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
	margin-top: 26px;
}

.containerLogin button:active {
	transform: scale(0.95);
}

.containerLogin button:focus {
	outline: none;
}

.containerLogin button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}

.containerLogin form {
	background-color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;
}

.containerLogin input {
    background-color: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    height: 62px;
    width: 68%;
}

.containerLogin {
    background-color: #fff;
    /* border-radius: 10px; */
    /* box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22); */
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    /* min-height: 480px; */
    height: 100%;
}

.form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}

.sign-in-container {
	left: 0;
	width: 50%;
	z-index: 2;
}

.containerLogin.right-panel-active .sign-in-container {
	transform: translateX(100%);
}

.sign-up-container {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.containerLogin.right-panel-active .sign-up-container {
	transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	animation: show 0.6s;
}

@keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}

.overlay-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: transform 0.6s ease-in-out;
	z-index: 100;
}

.containerLogin.right-panel-active .overlay-container{
	transform: translateX(-100%);
}

.forgetpassipnut{
	font-size: 10px;
    font-weight: 700;
}

.input-field{
	width: 69%;
}

#country_select{ 
	background-color: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    height: 62px;
    width: 100%;
    /* margin-left: -37px; */
}
.overlay {
    /* background: #FF416C;
    background: linear-gradient(to top, #009AAD, #008EB5); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #FFFFFF;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;


}

.containerLogin.right-panel-active .overlay {
  	transform: translateX(50%);
}

.overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.overlay-left {
	transform: translateX(-20%);
}

.containerLogin.right-panel-active .overlay-left {
	transform: translateX(0);
}

.overlay-right {
	right: 0;
	transform: translateX(0);
}

.containerLogin.right-panel-active .overlay-right {
	transform: translateX(20%);
}

.social-container {
	margin: 20px 0;
}

.social-container a {
	border: 1px solid #DDDDDD;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 40px;
}

.copy{
	position: relative;
    top: 80px;
}

.divErrMessage{
    margin-block: 30px;
    /* width: 146px; */
    background-color: red;
    border-radius: 12px;
	align-items: center;
	text-align: center;
	vertical-align: middle;
	padding-inline: 20px;
	padding-block: 0px;
}

.divSuccessMessage{
    margin-block: 30px;
    /* width: 146px; */
    border-radius: 12px;
	align-items: center;
	text-align: center;
	vertical-align: middle;
	padding-inline: 20px;
	padding-block: 0px;
}



.divmsg p{

	font-size: 16px;
	font-family: system-ui;
    position: relative;
    top: -20px;
    color: white;
    padding: 10px;
    font-weight: 700;

}

.span-danger{

	color: red;
	width: 43%;

    text-align: left;
    /* width: 67%; */
    justify-content: left;
    display: flex;
    margin-left: 126px;
    margin-right: auto;
}

.zlogo{
    /* margin-left: 49%; */
    text-align: right;
    justify-content: right;
    /* display: flex; */
    margin-top: 6%;

}

.bienvenudiv{
    position: relative;
    bottom: 41%;
    transform: translateY(-50%);
}
.bienvenudiv  p{
    line-height: 0;
    font-size: 31px;
    margin: 8px
}
.bienvenudiv  h1{
    font-size: 63px;
}

.overlay-panel{
	background-size: contain;
	background-repeat: no-repeat;

    width: 100%;
}
@media(max-width: 767px){
	.containerLogin.right-panel-active .sign-up-container {
		width: 280px;
		transform: none;
		margin: auto;
		position: relative;
	}
	.containerLogin form {
		padding: 0;
	}
	.containerLogin input {
		background-color: #eee;
		border: none;
		padding: 12px 15px;
		margin: 8px 0;
		height: auto;
		width: 100%;
	}
	.containerLogin.right-panel-active .overlay-container {
		display: none;
	}
}