
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f9f9fb;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #009aad;
  border-radius: 19px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0079ff;
}

*{
  font-family: sans-serif ;
}

button{
  cursor: pointer;
}
h2 {
  color: #009aad;
  font-size: 24px;
  font-weight: bold;
  margin: 5px 0 10px 0;
}
p {
  margin: 0 0 0.8rem 0;
}
h1 {
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #009aad;
}
#google_translate_element {
  position: fixed;
  right: 0;
  top: 0;
  background: white;
  padding: 5px;
  z-index: 999;
  height: 35px;
  overflow: hidden;
}
.translated-ltr #google_translate_element, .translated-rtl #google_translate_element {
  display: none;
}
#google_translate_element img { display: inline-block; }